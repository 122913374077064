import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import UploadPage from './pages/UploadPage';
import QuestionPage from './pages/QuestionPage';
import ReactGA from "react-ga4";

ReactGA.initialize("G-B2KG6LFMQL"); 

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/upload" element={<UploadPage />} />
                    <Route path="/questions" element={<QuestionPage />} />
                    <Route path="/" element={<Navigate replace to="/upload" />} />

                </Routes>
        
            </div>
        </Router>
    );
}

export default App;
