import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ChatWindow from '../components/ChatWindow';
import { MessageCircle } from 'lucide-react';
import ReferenceTextWindow from '../components/ReferenceTextWindow';
import ProgressBar from '../components/ProgressBar'; // Import the ProgressBar component

function QuizPage() {
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [showRefText, setShowRefText] = useState(false);
  const [refText, setRefText] = useState('');
  const reAddCurrentQuestionRef = useRef(null);
  const reAddCurrentFeedbackRef = useRef(null);
  const [movingToNextQuestion, setMovingToNextQuestion] = useState(false);
  const [disableInput, setDisableInput] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [currentChildIndex, setCurrentChildIndex] = useState(0);
  const [getCurrentChildCount, setGetCurrentChildCount] = useState(0);


  const fetchQuestion = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/api/get_question', { withCredentials: true });

      console.log('get_question', response.data); // Log the question for debugging

      if (response.data.question){
        setCurrentQuestion(response.data.question);
      }

      // Update progress-related state variables
      setCurrentQuestionIndex(response.data.current_question_index);
      setTotalQuestions(response.data.total_questions);
      setCurrentChildIndex(response.data.current_child_index);
      setGetCurrentChildCount(response.data.get_current_child_count);
      
      if(response.data.chat_evaluation){
        console.log('chat_evaluation', response.data.chat_evaluation);
        setDisableInput(true);
        setFeedback(response.data.chat_evaluation);
      }

    } catch (error) {
      setCurrentQuestion(null);
      setFeedback('Error loading question.');
    }
  };

  useEffect(() => {
    fetchQuestion();
  }, []);

  const handleAnswerSubmit = async (answer) => {
    if (!currentQuestion) return;

    try { 
      console.log('submitting answer', answer);
      const response = await axios.post(process.env.REACT_APP_API_URL + '/api/submit_answer', {
        answer: answer
      }, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('submitted_answer')

      console.log(response.data)

      
      if (response.data.feedback === feedback && reAddCurrentFeedbackRef.current) {
        // If feedback is the same (e.g two correct), add it again
        reAddCurrentFeedbackRef.current()
      }else{
        // add feedback, doesn't work if it's the same
        setFeedback(response.data.feedback);
      }
      await new Promise(resolve => setTimeout(resolve, 0));
      

      if (response.data.improperly_answered) {
        setMovingToNextQuestion(true);
      } 

      if (response.data.text) {
        setRefText(response.data.text);
        // asking same question again, it needs to be added manually
        if (reAddCurrentQuestionRef.current) {
          reAddCurrentQuestionRef.current();
        }
      } else {
        fetchQuestion(); 
      }

    } catch (error) {
      console.error('Failed to submit answer', error);
      setFeedback('Error submitting answer.');
    }
  };

  const handleResetSession = async () => {
    try {
      await axios.post(process.env.REACT_APP_API_URL + '/api/reset_session', {}, { withCredentials: true });
      setCurrentQuestion(null);
      setCurrentChildIndex(0);
      setGetCurrentChildCount(0);
      setCurrentQuestionIndex(0);
      setFeedback('');
      fetchQuestion();
      setDisableInput(false);
    } catch (error) {
      console.error('Failed to reset session', error);
      setFeedback('Error resetting session.');
    }
  };

  const handleShowHint = () => {
    setShowRefText(true);
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      padding: '10px',
      backgroundColor: '#1a1a1a',
    }}>
      {/* Discord feedback icon */}
      <a
        href="https://discord.gg/tJjV9QSf"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          position: 'fixed',
          top: '20px',
          right: '20px',
          backgroundColor: '#7289DA',
          color: 'white',
          borderRadius: '5px',
          padding: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          textDecoration: 'none',
          boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
        }}
      >
        <MessageCircle size={24} />
        <span style={{ marginLeft: '5px', fontSize: '14px' }}>Feedback?</span>
      </a>

      <ProgressBar 
        current={currentQuestionIndex}
        max={totalQuestions} 
        label="Questions"
      />

      {getCurrentChildCount > 0 &&  getCurrentChildCount !== currentChildIndex && (
        <ProgressBar 
          current={currentChildIndex} 
          max={getCurrentChildCount} 
          label="Side quest questions"
        />
      )}

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center', 
        height: '100%',
        width: '100%',
      }}>
        <ChatWindow 
          currentQuestion={currentQuestion} 
          onSubmitAnswer={handleAnswerSubmit} 
          feedback={feedback} 
          onReAddCurrentQuestion={(func) => { reAddCurrentQuestionRef.current = func; }}
          onNewMessage={handleShowHint}
          movingToNextQuestion={movingToNextQuestion}
          disableInput={disableInput}
          onReAddFeedback={(func) => { reAddCurrentFeedbackRef.current = func; }}
        />
      </div>

      {showRefText && (
        <ReferenceTextWindow
          text={refText}
          onClose={() => setShowRefText(false)}
        />
      )}
    </div>
  );
}

export default QuizPage;